import { defineStore } from 'pinia';
import { LinkData } from '@emotioncod/cm-design-system';

export const useBreadbrumb = defineStore('breadbrumbStore', () => {
  const breadcrumbs = ref<LinkData[]>([]);

  return {
    breadcrumbs
  };
});
